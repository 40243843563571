<template>
  <b-overlay :show="formShow" rounded="md" no-fade>
    <form-wizard
      color="#3178cf"
      ref="wizard"
      scope="props"
      :title="null"
      :subtitle="null"
      shape="square"
      layout="vertical"
      class="vertical-steps steps-transparent hide-button"
    >
      <tab-content title="Home" icon="feather icon-file-text">
        <b-row class="mb-2">
          <b-col cols="12" xl="12" md="12" v-if="homeInventoryList.length > 0">
            <draggable
              v-model="homeInventoryList"
              class="list-group list-group-flush cursor-move"
              tag="ul"
            >
              <transition-group
                type="transition"
                name="flip-list"
                class="instrument"
              >
                <b-list-group-item
                  v-for="(inventory, index) in homeInventoryList"
                  :key="inventory.id"
                  tag="li"
                >
                  <b-col cols="12" xl="12" md="12" class="d-flex  ">
                    <div class="d-flex">
                      <b-img-lazy
                        thumbnail
                        class="rounded"
                        width="100px"
                        @error.native="onError"
                        :src="inventory.featuredImage"
                        v-if="inventory.featuredImage != null"
                      />
                      <b-img-lazy
                        thumbnail
                        class="rounded"
                        width="100px"
                        :src="
                          inventory.images
                            ? inventory.images[0]
                            : '@/assets/images/no-car.png'
                        "
                        v-else
                      />
                      <div class="ml-5">
                        <b-card-text class="font-weight-bold mb-0">
                          <b
                            :class="index < 3 ? 'text-success' : 'text-danger'"
                            >{{
                              inventory.modelyear +
                                " " +
                                inventory.make +
                                " " +
                                inventory.model
                            }}</b
                          >
                        </b-card-text>
                        <small class="font-weight-bold"
                          >${{
                            inventory.price
                              ? " " + formatPrice(inventory.price, 2)
                              : " " + formatPrice(0)
                          }}
                        </small>
                      </div>
                    </div>
                  </b-col>
                </b-list-group-item>
              </transition-group>
            </draggable>

            <b-button
              class="mt-2"
              block
              variant="primary"
              @click="sendhomeInventoryListToWeb(homeInventoryList)"
              >Update Home List</b-button
            >
          </b-col>
          <b-col
            v-else
            cols="12"
            xl="12"
            md="12"
            class="text-center align-center"
            align-v="center"
          >
            <h2>There is no shared inventory yet.</h2>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Collection" icon="feather icon-file-text">
        <!-- <b-list-group-item v-for="(inventory, index) in collectionInventoryList" :key="index" tag="li" :disabled="false">
          <b-col cols="12" xl="12" md="12" class="d-flex">
            <div class="d-flex">
              <b-img-lazy thumbnail class="rounded" width="100px" @error.native="onError" :src="inventory.featuredImage" v-if="inventory.featuredImage != null" />
              <b-img-lazy thumbnail class="rounded" width="100px" :src="inventory.images ? inventory.images[0] : '@/assets/images/no-car.png'" v-else />
              <div class="ml-5">
                <b-card-text class="font-weight-bold mb-0">
                  <b>{{ inventory.modelyear + ' ' + inventory.make + ' ' + inventory.model }}</b>
                </b-card-text>
                <small class="font-weight-bold">${{ inventory.price ? ' ' + formatPrice(inventory.price, 2) : ' ' + formatPrice(0) }} </small>
              </div>
            </div>
          </b-col>
        </b-list-group-item> -->
        <b-row class="mb-2">
          <b-col
            cols="12"
            xl="12"
            md="12"
            v-if="collectionInventoryList.length > 0"
          >
            <draggable
              v-model="collectionInventoryList"
              class="list-group list-group-flush cursor-move"
              tag="ul"
            >
              <transition-group
                type="transition"
                name="flip-list"
                class="instrument"
              >
                <b-list-group-item
                  v-for="(inventory, index) in collectionInventoryList"
                  :key="inventory.id"
                  tag="li"
                >
                  <b-col cols="12" xl="12" md="12" class="d-flex  ">
                    <div class="d-flex">
                      <b-img-lazy
                        thumbnail
                        class="rounded"
                        width="100px"
                        @error.native="onError"
                        :src="inventory.featuredImage"
                        v-if="inventory.featuredImage != null"
                      />
                      <b-img-lazy
                        thumbnail
                        class="rounded"
                        width="100px"
                        :src="
                          inventory.images
                            ? inventory.images[0]
                            : '@/assets/images/no-car.png'
                        "
                        v-else
                      />
                      <div class="ml-5">
                        <b-card-text class="font-weight-bold mb-0">
                          <b
                            :class="index < 3 ? 'text-success' : 'text-danger'"
                            >{{
                              inventory.modelyear +
                                " " +
                                inventory.make +
                                " " +
                                inventory.model
                            }}</b
                          >
                        </b-card-text>
                        <small class="font-weight-bold"
                          >${{
                            inventory.price
                              ? " " + formatPrice(inventory.price, 2)
                              : " " + formatPrice(0)
                          }}
                        </small>
                      </div>
                    </div>
                  </b-col>
                </b-list-group-item>
              </transition-group>
            </draggable>

            <b-button
              class="mt-2"
              block
              variant="primary"
              @click="sendCollectionInventoryListToWeb(collectionInventoryList)"
              >Update collection List</b-button
            >
          </b-col>
          <b-col
            v-else
            cols="12"
            xl="12"
            md="12"
            class="text-center align-center"
            align-v="center"
          >
            <h2>There is no collection inventory yet.</h2>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </b-overlay>
</template>

<script>
import {
  BImg,
  BCard,
  BFormGroup,
  BFormInput,
  BCardBody,
  BFormCheckbox,
  BRow,
  BFormTextarea,
  BCol,
  BFormFile,
  BContainer,
  BMediaAside,
  BButton,
  BOverlay,
  VBTooltip,
  BListGroupItem,
  BAvatar,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import store from "@/store";
import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { ref, onUnmounted, reactive } from "@vue/composition-api";
import inventoryStoreModule from "../inventoryStoreModule";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Cleave from "vue-cleave-component";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import router from "@/router";
import axiosIns from "@/libs/axios";

export default {
  components: {
    BRow,
    BCol,
    BFormFile,
    BContainer,
    BMediaAside,
    BCard,
    BCardBody,
    BFormTextarea,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BImg,
    BButton,
    BOverlay,
    FormWizard,
    TabContent,
    WizardButton,
    BListGroupItem,
    BAvatar,
    draggable,
    VBTooltip,

    //validation
    ValidationProvider,
    ValidationObserver,

    ToastificationContent,
    BCardActions,
    Cleave,
  },

  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  setup() {
    const INVENTORY_APP_STORE_MODULE_NAME = "inventory";
    // Register module
    if (!store.hasModule(INVENTORY_APP_STORE_MODULE_NAME))
      store.registerModule(
        INVENTORY_APP_STORE_MODULE_NAME,
        inventoryStoreModule
      );
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVENTORY_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVENTORY_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {
    var wizard = this.$refs.wizard;
    wizard.activateAll();
    this.gethomeInventory();
    this.getCollectionInventory();
  },

  data() {
    return {
      formShow: false,
      homeInventoryList: [],
      collectionInventoryList: [],
    };
  },

  watch: {},

  computed: {},

  methods: {
    gethomeInventory() {
      this.formShow = true;
      store
        .dispatch("inventory/getHomeInventory")
        .then((res) => {
          this.formShow = false;
          this.homeInventoryList = res.data.inventories;
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching home inventory list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    getCollectionInventory() {
      this.formShow = true;
      store
        .dispatch("inventory/getCollectionInventory")
        .then((res) => {
          this.formShow = false;
          this.collectionInventoryList = res.data.inventories;
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching collection inventory list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    sendhomeInventoryListToWeb(editedList) {
      this.formShow = true;
      editedList.forEach((object, i) => (object.homeIndexList = i));
      store
        .dispatch("inventory/updateHomeInventoryList", editedList)
        .then((res) => {
          this.formShow = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Successful",
              text: "✔️ List Update Successful",
              icon: "ThumbsUpIcon",
              variant: "success",
            },
          });
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error update home inventory list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    sendCollectionInventoryListToWeb(editedList) {
      this.formShow = true;
      editedList.forEach((object, i) => (object.collectionIndexList = i));
      store
        .dispatch("inventory/updateCollectionInventoryList", editedList)
        .then((res) => {
          this.formShow = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Successful",
              text: "✔️ List Update Successful",
              icon: "ThumbsUpIcon",
              variant: "success",
            },
          });
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error update home inventory list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    onError(event) {
      event.target.src = "../no-car.png";
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";

.wizard-tab-container {
  display: block;
  animation: fadeInRight 0.3s;
}
.list-group-item {
  transition: all 0.5s;
}

.hide-button .wizard-btn {
  display: none;
}
</style>
